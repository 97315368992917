/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./StudentDetails.css";
// import { Link } from "react-router-dom";
import AuthLeft from "../Shared/authLeft/authLeft";
import VerifyModal from "./VerifyModal/VerifyModal";
import {
  getUniversity,
  getCollegeByUniversity,
  getBranchList,
  getUserDetail,
  getUserData,
  createUserDb,
  updateUserDb,
  sendCode,
} from "../../utls/utls";
import email from "../LandingPage/image/email (5).png";
import phone from "../LandingPage/image/phone.png";
import University_icon from "../LandingPage/image/university.png";
import college_icon from "../LandingPage/image/college_icon.png";
import usn_icon from "../LandingPage/image/usn_icon.png";
import { ToastContainer, toast } from "react-toastify";
// import SearchableDropDown from "../Shared/SearchableDropDown/SearchableDropDown";
// import branch_icon from '../LandingPage/image/branch_icon.png'
// import semester_icon from '../LandingPage/image/semester_icon.png'

const StudentDetails = () => {
  const navigate = useNavigate();
  const [university, setUniversity] = useState([]);
  const [college, setCollege] = useState();
  const [branch, setBranch] = useState([]);
  // console.log(university)
  const [verifyUser, setVerifyUser] = useState({
    secretCode: "",
    email: "",
  });
  // console.log(verifyUser);
  const [updateUser, setUpdateUser] = useState({
    email: "",
    phone: "",
    university: "",
    college: "",
    branch: "",
    roll_no: "",
    semester: "",
  });

  const [emailData, setEmailData] = useState({
    secretCode: "",
    email: "",
    name: "",
  });
  // console.log(emailData);
  const [isVerified, setIsVerified] = useState(false);
  const [showModal, setShowModal] = useState(false);
  // console.log(isVerified);
  // const current_url = window.location.pathname.split("/");
  const user_id = localStorage.getItem("id");

  // Function for arranging array in alphabatical order
  const orderAlphabet = (item) => {
    return item?.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      } else {
        return 1;
      }
    });
  };

  const universityList = async () => {
    const res = await getUniversity();
    setUniversity(orderAlphabet(res?.data?.universityList));
    // console.log(res?.data?.universityList)
  };

  const collegeList = async (universityId) => {
    const res = await getCollegeByUniversity(universityId);
    setCollege(orderAlphabet(res?.data?.collegeList));
    // console.log(res?.data?.collegeList)
  };
  const branchList = async () => {
    const res = await getBranchList();
    setBranch(orderAlphabet(res?.data?.branchList));
  };

  const checkIsverified = async (user_id, userData) => {
    await getUserData(user_id)
      .then(async (res) => {
        if (!res?.data?.user[0]) {
          // console.log(userData);
          await createUserDb(userData);
        }
      })
      .then(async () => {
        await getUserData(user_id).then((res) => {
          // console.log(res?.data);
          setEmailData({
            ...emailData,
            secretCode: res?.data?.user[0]?.secret,
            email: res?.data?.user[0]?.email,
            name: res?.data?.user[0]?.name,
          });
          setIsVerified(res?.data?.user[0]?.isverified);
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const userDetail = async (user_id) => {
    await getUserDetail(user_id)
      .then((res) => {
        setVerifyUser({
          ...verifyUser,
          email: res?.data?.email,
        });
        setUpdateUser({
          ...updateUser,
          email: res?.data?.email,
        });
        const id = res?.data?.id;
        const userData = {
          user_id: res?.data?.id,
          name: res?.data?.full_name,
          email: res?.data?.email,
        };
        checkIsverified(id, userData);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // console.log(updateUser)

  let name, value;
  const handleChangeRegister = (event) => {
    name = event.target.name;
    value = event.target.value;

    setUpdateUser({ ...updateUser, [name]: value });
    if (name === "university") {
      collegeList(value);
    }
  };
  // console.log(updateUser.email);
  const handleSubmit = async (e) => {
    e.preventDefault();
    switch (true) {
      case !updateUser.email:
        return toast.warning("User not found");

      case !updateUser.phone:
        return toast.warning("please fill the phone field before submit");

      case !updateUser.university:
        return toast.warning(
          "please select the university field before submit"
        );

      case !updateUser.college:
        return toast.warning("please select the college field before submit");

      case !updateUser.roll_no:
        return toast.warning("please fill the USN No field before submit");

      case !updateUser.branch:
        return toast.warning("please select the branch field before submit");

      case !updateUser.semester:
        return toast.warning("please select the semester field before submit");

      default:
        break;
    }
    // if (!(updateUser.email && updateUser.phone && updateUser.university && updateUser.college && updateUser.branch && updateUser.roll_no && updateUser.semester)) {
    //   // return alert("Please fill all the fields before submit")
    //   return toast.error("Please fill all the fields before submit");
    // }

    await updateUserDb(updateUser);
    navigate(`/dashboardTwo`);
  };

  const sendCodeHandler = async (e) => {
    if (isVerified === false) {
      e.preventDefault();
      setShowModal(true);
      const res = await sendCode(emailData);
      console.log(res);
    }
  };

  useEffect(() => {
    universityList();
    branchList();
    userDetail(user_id);
  }, [showModal, isVerified]);
  // console.log("data:",registerUser);
  return (
    <>
      <div className="student_details_container">
        <VerifyModal
          className="verify_modal"
          setVerifyUser={setVerifyUser}
          verifyUser={verifyUser}
          showModal={showModal}
          setShowModal={setShowModal}
          sendCodeHandler={sendCodeHandler}
        />
        <AuthLeft />

        <div className="student_details_right">
          <div className="student_details_hero_icon"></div>
          <h1>Sign Up</h1>
          <h3 className="dashboard_subHeading">
            Welcome! please enter your details.
          </h3>
          <h3 className="dashboard_subHeading">
            {isVerified
              ? "Enter your academic details for complete registration"
              : "please first verify your Email to proceed further"}
          </h3>
          <div className="student_detail">
            {/* Email Container */}
            <div className="email_container">
              <div className="email_icon_text_container">
                <div className="email_icon_box">
                  <div
                    className="email_icon"
                    style={{
                      backgroundImage: `url(${email})`,
                      height: "1.7rem",
                      width: "2.2rem",
                    }}
                  ></div>
                </div>

                <h3 className="email_text dashboard_subHeading">
                  {updateUser?.email || ""}
                </h3>
              </div>
              <button className="verify_btn" onClick={sendCodeHandler}>
                {isVerified ? "verified" : "verify"}
              </button>
            </div>

            {/* Container for academic details */}
            <div
              className="user_details_container"
              style={isVerified ? { display: "initial" } : { display: "none" }}
            >
              {/* Phone Number Container */}
              <div className="email_container">
                <div className="email_icon_text_container">
                  <div className="email_icon_box">
                    <div
                      className="email_icon"
                      style={{
                        backgroundImage: `url(${phone})`,
                        height: "2.6rem",
                        width: "1.7rem",
                      }}
                    ></div>
                  </div>

                  <input
                    type="number"
                    name="phone"
                    value={updateUser?.phone}
                    onChange={handleChangeRegister}
                    placeholder="Phone Number"
                    className="email_text"
                    id="email_verify"
                  />
                </div>
              </div>

              {/* <SearchableDropDown /> */}

              {/* Select University */}
              <div className="email_container">
                <div className="email_icon_box">
                  <div
                    className="email_icon"
                    style={{
                      backgroundImage: `url(${University_icon})`,
                      height: "2.3rem",
                      width: "1.8rem",
                    }}
                  ></div>
                </div>
                <select
                  name="university"
                  id="university"
                  onChange={handleChangeRegister}
                  value={updateUser?.university}
                >
                  <option className="option" value="university">
                    Choose University
                  </option>
                  {university?.map((elm, index) => (
                    <option className="option" value={elm?._id} key={index}>
                      {elm?.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Select College */}
              <div className="email_container">
                <div className="email_icon_box">
                  <div
                    className="email_icon"
                    style={{
                      backgroundImage: `url(${college_icon})`,
                      height: "2rem",
                      width: "1.85rem",
                    }}
                  ></div>
                </div>

                <select
                  name="college"
                  id="university"
                  onChange={handleChangeRegister}
                  value={updateUser?.college}
                >
                  <option className="option" value="university">
                    Choose College
                  </option>
                  {college?.map((elm, index) => {
                    return (
                      <option className="option" value={elm?._id} key={index}>
                        {elm?.name.toUpperCase()}
                      </option>
                    );
                  })}
                </select>
              </div>

              {/* Enter Roll no */}
              <div
                className="email_container"
                style={{ justifyContent: "flex-start" }}
              >
                <div className="email_icon_box">
                  <div
                    className="email_icon"
                    style={{
                      backgroundImage: `url(${usn_icon})`,
                      height: "1.8rem",
                      width: "1.8rem",
                    }}
                  ></div>
                </div>

                <input
                  type="text"
                  name="roll_no"
                  value={updateUser?.rollNo}
                  id="roll_no"
                  onChange={handleChangeRegister}
                  placeholder="USN Number"
                  className="email_text"
                />
              </div>

              {/* Input box for entering branch and */}
              <div className="email_container">
                <select
                  name="branch"
                  id="branch"
                  onChange={handleChangeRegister}
                  value={updateUser?.branch}
                >
                  <option className="option" value="Branch">
                    Branch
                  </option>
                  {branch?.map((elm, index) => (
                    <option className="option" value={elm?._id} key={index}>
                      {elm?.name}
                    </option>
                  ))}
                </select>

                <select
                  name="semester"
                  id="university"
                  onChange={handleChangeRegister}
                  value={updateUser?.semester}
                >
                  <option className="option" value="university">
                    Semester
                  </option>
                  <option className="option" value="1">
                    First
                  </option>
                  <option className="option" value="2">
                    Second
                  </option>
                  <option className="option" value="3">
                    Third
                  </option>
                  <option className="option" value="4">
                    Fourth
                  </option>
                  <option className="option" value="5">
                    Fifth
                  </option>
                  <option className="option" value="6">
                    Sixth
                  </option>
                  <option className="option" value="7">
                    Seventh
                  </option>
                  <option className="option" value="8">
                    Eighth
                  </option>
                </select>
              </div>

              <button className="sign_up_btn" onClick={handleSubmit}>
                Sign Up
              </button>
            </div>

            {/* <h4 className="register_text">
              Do you have an account?
              <span>
                <Link to="https://makes.mindmatrix.io/users/sign_in">
                  <button className="register_btn">Login</button>
                </Link>
              </span>
            </h4> */}
          </div>
        </div>
      </div>
      <ToastContainer style={{ fontSize: "1.35rem" }} />
    </>
  );
};

export default StudentDetails;
