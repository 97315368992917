// import * as React from "react";
import axios from "axios";
// const user_id = 173198193;

// headers for all API requests from thincific
const headers = {
  "Content-Type": "application/json",
  accept: "application/json",
  "X-Auth-API-Key": "91fee3f0baffc4be1fffdf99ba130937",
  "X-Auth-Subdomain": "mindmatrix-s-school",
};
    
// Function to get all details of user from thincific
export const getUserDetail = async (user_id) => {
  const res = await axios.get(
    `https://api.thinkific.com/api/public/v1//users/${user_id}`,
    {
      headers: { ...headers },
    }
  );

  return res;
};

// Function to get all the enrolled courses
export const getEnrollmentByUserId = async (user_id) => {
  try {
    const res = await axios.get(
      `https://api.thinkific.com/api/public/v1/enrollments?page=1&limit=50&query[user_id]=${user_id}`,
      {
        headers: { ...headers },
      }
    );
    const data = await res.data.items;

    return await Promise.all(
      data.map(async (elm) => {
        const courseId = elm?.course_id;
        const course = await axios.get(
          `https://api.thinkific.com/api/public/v1//courses/${courseId}`,
          {
            headers: { ...headers },
          }
        );
        elm.course = course?.data;

        const product_Id = elm?.course?.product_id;
        const product = await axios.get(
          `https://api.thinkific.com/api/public/v1//products/${product_Id}`,
          {
            headers: { ...headers },
          }
        );
        elm.product = product?.data;

        const collection_Id = elm?.product?.collection_ids[0];
        if (collection_Id) {
          const collection = await axios.get(
            `https://api.thinkific.com/api/public/v1/collections/${collection_Id}`,
            {
              headers: { ...headers },
            }
          );
          elm.collection = collection?.data;
        }

        if (elm?.product?.hidden === false && elm?.expired === false) {
          return elm;
        }
      })
    );
  } catch (error) {
    console.log(error);
  }
};

// Function to get Enrollment by userId

export const getEnrollmentId = async (user_id) => {
  try {
    return await axios.get(
      `https://api.thinkific.com/api/public/v1/enrollments?page=1&limit=50&query[user_id]=${user_id}`,
      {
        headers: { ...headers },
      }
    );
  } catch (error) {
    console.log(error);
  }
};

// Function to get courses by courseId
export const getCourseByCourseId = async (course_Ids) => {
  // console.log(course_Ids)
  try {
    return await Promise.all(
      course_Ids.map(async (elm) => {
        if (elm?.expired !== true) {
          const courseId = elm?.course_id;
        const course = await axios.get(
          `https://api.thinkific.com/api/public/v1/courses/${courseId}`,
          {
            headers: { ...headers },
          }
        );
        elm.course = course?.data;

        const product_Id = elm?.course?.product_id;
        const product = await axios.get(
          `https://api.thinkific.com/api/public/v1/products/${product_Id}`,
          {
            headers: { ...headers },
          }
        );
        elm.product = product?.data;

        const collection_Id = elm?.product?.collection_ids[0];
        
        if (collection_Id && elm?.product?.hidden === false && elm?.product?.status !== "draft") {
          const collection = await axios.get(
            `https://api.thinkific.com/api/public/v1/collections/${collection_Id}`,
            {
              headers: { ...headers },
            }
          );
          elm.collection = collection?.data;
        }
          if (elm?.product?.hidden === false && elm?.product?.status !== "draft") {
            return elm;
          }
        }
      })
    );
  } catch (error) {
    console.log(error);
  }
};

// Get membeship buldle by bundle Ids
export const getMembershipBundle = async (bundle_ids) => {
  try {
    return await Promise.all(
      bundle_ids.map(async (elm) => {
        const resp = await axios.get(`https://api.thinkific.com/api/public/v1/bundles/${elm}`, {
          headers: {...headers}
        })
        return resp?.data
      })
    )
  } catch (error) {
    console.log(error)
  }
}

// Get activity course by collection name
export const getActivityByCategory = async () => {
  try {
      const activityCollection = await axios.get('https://api.thinkific.com/api/public/v1/collections/1115850',{
        headers: {...headers}
      })
      return await Promise.all(activityCollection.data.product_ids?.map(async (elm) => {
        const resp =  await axios.get(`https://api.thinkific.com/api/public/v1/products/${elm}`,{
          headers: {...headers}
        })
        return resp.data
      }))
  } catch (error) {
    console.log(error)
  }
}

// Function for getting the chapter name from session id

export const getChapterName = async (sessionId) => {
  try {
    
  } catch (error) {
    console.log(error)
  }
}

// Function to get list of university
export const getUniversity = async () => {
  try {
    return await axios.get("https://mindmatrix.io/api/getUniversity", {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    // console.log(res);
  } catch (error) {
    console.log(error);
  }
};

// test for 

export const getUserIdList = async () => {
  try {
    return await axios.get("https://mindmatrix.io/api/getAllUser", {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    // console.log(res);
  } catch (error) {
    console.log(error);
  }
};

// Function to get list of college by university
export const getCollegeByUniversity = async (universityId) => {
  try {
    return await axios.get(
      `https://mindmatrix.io/api/getCollegeByUniversity/${universityId}`,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
  } catch (error) {
    console.log(error);
  }
};

// Function to get the list of all branch
export const getBranchList = async () => {
  try {
    return await axios.get("https://mindmatrix.io/api/getBranch", {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
  } catch (error) {
    console.log(error);
  }
};

// Function get details of user by user id from our database
export const getUserData = async (user_id) => {
  try {
    const result = await axios({
      method: "get",
      url: `https://mindmatrix.io/api/getUser/${user_id}`,
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2OTM0NzM4MTQsImV4cCI6MTY5MzczMzAxNH0.7n-aJrA0P0jlXh8bmmpWuAiGOGXWtsWADh1DK27SNIM",
      },
    });
    return result;
  } catch (error) {
    console.log(error);
  }
};

// Function to create new user in our database
export const createUserDb = async (CreateUserData) => {
  const result = await axios({
    method: "post",
    url: "https://mindmatrix.io/api/createUser",
    data: CreateUserData,
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
  });
  console.log(result);
  return result;
};

// Function to verify the secret code of user
export const verifyUserDb = async (verifyUserData) => {
  const result = await axios({
    method: "post",
    url: "https://mindmatrix.io/api/verifyUser",
    data: verifyUserData,
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
  });
  return result;
};

// Function to update the reamaining details of user
export const updateUserDb = async (updateUserData) => {
  const result = await axios({
    method: "post",
    url: "https://mindmatrix.io/api/updateUser",
    data: updateUserData,
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
  });
  return result;
};

// Function to send mail
export const sendCode = async (mailData) => {
  const result = await axios({
    method: "post",
    url: "https://mindmatrix.io/api/sendMail",
    data: mailData,
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
  });
  return result;
};

// Function to get the reccomendation by semester, branch and university
export const getAllReccomendedCourse = async (user) => {
  // console.log(user)
  try {
    const result = await axios({
      method: "post",
      url: "https://mindmatrix.io/api/readCourseReccomendation",
      data: user,
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    
    return result;
  } catch (error) {
    console.log(error);
  }
};

// Function for getting the list of all users
export const getAllUsers = async () => {
  try {
    const result = await axios({
      method: "get",
      url: "https://mindmatrix.io/api/getAllUser",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return result;
  } catch (error) {
    console.log(error);
  }
};

// Function to generate user token from pensil
export const generateUserTokenFromPensil = async (user) => {
  try {
    const result = await axios({
      method: "post",
      url: "https://api.pensil.in/api/3pc/generate-user-token",
      data: user,
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        communityid: "617772a2a48ddd06ac20ba9a",
        clientid: "617772a2a48ddd06ac20ba9a.dd789b117f4d2348ae974ce094318f7e",
        clientsecret:
          "617772a2a48ddd06ac20ba9a.488a0ba8c191d56feb58111eb86486f9182c920a",
      },
    });
    // console.log(result)
    return result;
  } catch (error) {
    console.log(error);
  }
};

// Function to get recommendation from rcommendation engine
export const getRecommendation = async () => {
  try {
    const result = await axios({
      method: "get",
      url: "https://rs.npbridge.com/recommend_course/api?field_mind_matrix_tags=android&field_learning_level=Beginner",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return result;
  } catch (error) {
    console.log(error);
  }
};

// Function to upload the assignment pdf for chatgpt
export const uploadAssignment = async (file) => {
  console.log(file);
  try {
    const result = await axios({
      method: "post",
      url: "https://mindmatrix.io/api/createAssignment",
      // https://mindmatrix.io/api/
      data: file,
      headers: {
        "Content-Type": "multipart/form-data",
        accept: "/*/",
        // "Access-Control-Allow-Origin": "*",
      },
    });
    console.log(result);

    return result;
  } catch (error) {
    console.log(error);
  }
};
// http://localhost:3001/createUserManually

// Function for chatgpt respnonse
export const chatGptResponse = async (text) => {
  try {
    const result = await axios({
      method: "post",
      url: "https://api.openai.com/v1/chat/completions",
      data: {
        model: "gpt-3.5-turbo",
        messages: [
          {
            role: "system",
            content:
              "\"Evaluate the assignment submitted for the \"Vision to revenue\" under the \"stay ahead with generative AI\" based on the following parameters:\n\nCourse and Chapter Relevance: On a scale of 1 to 100, how relevant is the content to the course and chapter?\n\nTechnical Relevance: Please rate the technical accuracy and applicability of the content on a scale of 1 to 100.\n\nEngagement and Readability: How engaging and readable is the assignment'''s content? Rate it from 1 to 100.\n\nLanguage: Rate the use of language and writing style from 1 to 100, considering clarity and appropriateness.\n\nAccuracy of Information: On a scale of 1 to 100, how accurate is the information presented in the assignment?\n\nOriginality and Creativity: Please rate the originality and creative thinking demonstrated in the assignment on a scale of 1 to 100.\n\nCoherence and Flow: Rate the logical coherence and flow of the assignment'''s content from 1 to 100.\n\nDepth of Content: How well does the assignment cover the depth of the topic? Rate it from 1 to 100.\n\nConciseness vs. Detail: Rate the balance between conciseness and providing sufficient detail on a scale of 1 to 100.\nOverall Quality: Considering all parameters, provide an overall quality score for the assignment from 1 to 100.\n\nPlease provide scores for each parameter based on the evaluation of the assignment'''s content give score in json format.",
          },
          {
            role: "user",
            content: `${text}`,
          },
        ],
        temperature: 1,
        max_tokens: 1000,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
      },
      headers: {
        accept: "application/json",
        Authorization:
          "Bearer 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",
      },
    });
    return result;
  } catch (error) {
    console.log(error);
  }
};

// Function for the chatgptResponse of Activity post
export const ActivityPostChatGptResponse = async (text) => {
  try {
    const result = await axios(
      {
        method: "post",
      url: "https://api.openai.com/v1/chat/completions",
      data: {
        model: "gpt-3.5-turbo",
        messages: [
          {
            role: "system",
            content:
            "\"Evaluate the assignment submitted for the \"Act N Lead\" under the \"stay ahead with generative AI\" based on the following parameters:\n\nActivity and Scenario Relevance: On a scale of 1 to 100, how relevant is the content to the course and chapter?\n\nCritical Thinking: Please rate the technical accuracy and applicability of the content on a scale of 1 to 100.\n\nEngagement and Readability: How engaging and readable is the assignment'''s content? Rate it from 1 to 100.\n\nLanguage: Rate the use of language and writing style from 1 to 100, considering clarity and appropriateness.\n\nAccuracy of Information: On a scale of 1 to 100, how accurate is the information presented in the assignment?\n\nOriginality and Creativity: Please rate the originality and creative thinking demonstrated in the assignment on a scale of 1 to 100.\n\nCoherence and Flow: Rate the logical coherence and flow of the assignment'''s content from 1 to 100.\n\nOriginality and Creativity: How well does the assignment cover the depth of the topic? Rate it from 1 to 100.\n\nConciseness vs. Detail: Rate the balance between conciseness and providing sufficient detail on a scale of 1 to 100.\nOverall Quality: Considering all parameters, provide an overall quality score for the assignment from 1 to 100.\n\nPlease provide scores for each parameter based on the evaluation of the assignment'''s content give score in json format."},
          {
            role: "user",
            content: `${text}`,
          },
        ],
        temperature: 1,
        max_tokens: 1000,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
      },
      headers: {
        accept: "application/json",
        Authorization:
          "Bearer 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",
      },
      }
    )
    return result;
  } catch (error) {
    console.log(error)
  }
}

// Function for Faq questions by chatgpt
export const faqChatGptResponse = async (data, systemData) => {
  try {
    const result = await axios({
      method: "post",
      url: "https://api.openai.com/v1/chat/completions",
      data: {
        "model": "gpt-3.5-turbo",
        "messages": [systemData, ...data],
        "temperature": 1,
        "max_tokens": 256,
        "top_p": 1,
        "frequency_penalty": 0,
        "presence_penalty": 0
      },
      headers: {
        accept: "application/json",
        Authorization:
          "Bearer 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",
      },
    })
    console.log('request started')
    console.log(result)
    console.log('response came')
    return result
  } catch (error) {
    console.log(error)
  }
}

// Function for sending the mail with custom message and subjest
export const sendNormalMail = async (mailData) => {
  try {
    const result = await axios({
      method: "post",
      url: "https://mindmatrix.io/api/sendCustomMail",
      data: mailData,
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return result;
  } catch (error) {
    console.log(error)
  }
} 

// Create pensil post using API call
export const createPensilPost = async (data, token) => {
  try {
    const result = await axios({
      method: "post",
      url: "https://api.pensil.in/api/post",
      data: data,
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        "Authorization": `Bearer ${token}`
      },
    });
    return result;
  } catch (error) {
    console.log(error)
  }
}

// Fetch the list of groups by community id
export const pensilGroupById = async (token) => {
  try {
    const result = await axios({
      method: "get",
      url: `https://api.pensil.in/api/community/617772a2a48ddd06ac20ba9a/groups`,
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        "Authorization": `Bearer ${token}`
      },
    })
    // console.log(result)
    return result;
  } catch (error) {
    console.log(error)
  }
}
// https://api.pensil.in/api/post/{postId}/images

// Add photos in pensil post
export const PensilPhotosByPostId = async (postId, data, token) => {
  try {
    const result = await axios({
      method: "post",
      url: `https://api.pensil.in/api/post/${postId}/images`,
      data,
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        "Authorization": `Bearer ${token}`
      },
    });
    return result;
  } catch (error) {
    console.log(error)
  }
}

export const contactMessageMail = async (data) => {
  try {
    const result = await axios({
      method: "post",
      url: "https://mindmatrix.io/api/contactMail",
      data,
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    })
    return result;
  } catch (error) {
    console.log(error)
  }
}

export const randomCourses = async () => {
  try {
    const resp = await axios.get('https://api.thinkific.com/api/public/v1/products', {
      headers: {...headers}
    })

    return resp
  } catch (error) {
    console.log(error)
  }
}

